import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "決裁待ち", enum: "PENDING" },
  { value: 2, text: "決裁却下", enum: "REJECTED" },
  { value: 3, text: "新規登録", enum: "NEW" },
  { value: 4, text: "受注却下", enum: "DECLINED" },
  { value: 5, text: "発注済み", enum: "APPROVED" },
  { value: 6, text: "入荷待ち", enum: "WAITING" },
  { value: 7, text: "確保確定", enum: "SECURED" },
  { value: 8, text: "出荷済み", enum: "SHIPPED" },
  { value: 9, text: "キャンセル", enum: "CANCELED" },
]);

// export default createDefault([
//   { value: 1, text: "決裁待ち", enum: "PENDING" },
//   { value: 2, text: "決裁却下", enum: "REJECTED" },
//   { value: 3, text: "新規登録", enum: "NEW" },
//   { value: 4, text: "受注却下", enum: "DECLINED" },
//   { value: 5, text: "承認済み", enum: "APPROVED" },
//   { value: 6, text: "入荷待ち", enum: "WAITING" },
//   { value: 7, text: "出荷済み", enum: "SHIPPED" },
//   { value: 8, text: "キャンセル", enum: "CANCELED" },
// ]);
