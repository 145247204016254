import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "承認前", enum: "NEW" },
  { value: 2, text: "期間開始前", enum: "APPROVED" },
  { value: 3, text: "期間中", enum: "WITHINTERM" },
  { value: 4, text: "期間終了", enum: "ENDTERM" },
  { value: 5, text: "検品済み", enum: "INSPECTED" },
  { value: 6, text: "完了", enum: "COMPLETED" },
]);
