import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "承認待ち", enum: "PENDING" },
  { value: 2, text: "承認却下", enum: "REJECTED" },
  { value: 3, text: "発注済み", enum: "APPROVED" },
  { value: 4, text: "発注処理中", enum: "PROCESSING" },
  { value: 5, text: "出荷待ち", enum: "WAITING" },
  { value: 6, text: "出荷済み", enum: "SHIPPED" },
  { value: 7, text: "キャンセル", enum: "CANCELED" },
]);
