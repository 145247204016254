import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "受注済み", enum: "NEW" },
  { value: 2, text: "受注締め", enum: "DECLINED" },
  { value: 3, text: "発注済み", enum: "APPROVED" },
  { value: 4, text: "入荷待ち", enum: "WAITING" },
  { value: 5, text: "確保確定", enum: "SECURED" },
  { value: 6, text: "出荷済み", enum: "SHIPPED" },
  { value: 7, text: "キャンセル", enum: "CANCELED" },
]);

// export default createDefault([
//   { value: 1, text: "承認待ち" },
//   { value: 2, text: "受注却下" },
//   { value: 3, text: "承認済み" },
//   { value: 4, text: "発注待ち" },
//   { value: 5, text: "入荷待ち" },
//   { value: 6, text: "出荷済み" },
//   { value: 7, text: "キャンセル" },
// ]);
