import { createDefault } from "../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "月" },
  { value: 2, text: "火" },
  { value: 3, text: "水" },
  { value: 4, text: "木" },
  { value: 5, text: "金" },
  { value: 6, text: "土" },
  { value: 7, text: "日" },
]);
