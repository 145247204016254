import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "承認待ち" },
  { value: 2, text: "却下" },
  { value: 3, text: "出荷日回答待ち" },
  { value: 4, text: "納品予約待ち" },
  { value: 5, text: "検品待ち" },
  { value: 6, text: "検品済み" },
  { value: 7, text: "キャンセル" },
]);
