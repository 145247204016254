<template>
  <v-container fluid style="height: 100%">
    <v-timeline align-top dense v-if="histories_new.length > 0" class="ml-n12 mr-n10 mx-auto">
      <v-timeline-item
        v-for="history in histories_new"
        :key="history.historyId"
        :color="convColor(history.corporationType)"
        small
      >
        <v-card :class="`ml-n5 ${convColor(history.corporationType)} lighten-5 mx-auto`">
          <v-card-subtitle class="pb-0 mb-1">
            <v-row>
              <v-col>
                {{ history.operationDate | moment }}
              </v-col>
              <v-col>
                {{ history.functionName }}
              </v-col>
              <v-col> {{ history.groupName }} {{ history.userName }} </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text v-if="history.changes.length > 0" class="white text--primary">
            <v-simple-table class="mt-3" dense>
              <thead>
                <tr>
                  <th style="width: 30%">項目</th>
                  <th style="width: 40%">変更前</th>
                  <th style="width: 40%">変更後</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="change in history.changes" :key="change.fieldId">
                  <td class="text-caption" v-if="isField(change.fieldId)">{{ convfieldName(change.fieldId) }}</td>
                  <td class="text-caption" v-if="isField(change.fieldId)">{{ change.oldValue }}</td>
                  <td class="text-caption" v-if="isField(change.fieldId)">{{ change.newValue }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-timeline-item>
    </v-timeline>
  </v-container>
</template>

<script>
import moment from "moment";
import Columns from "./../../historyColumns.json";
import CorporationTypes from "../../consts/CorporationTypes";
import Consts from "../../consts/Decode";
export default {
  name: "SimpleHistory",
  props: ["histories", "functionCategory"],
  data() {
    return {
      corporationTypeName: "",
      Consts: Consts,
      histories_new: [],
    };
  },
  created() {
    if (this.isCafereoUser) {
      this.corporationTypeName = "cafereo";
    } else if (this.isMakerUser) {
      this.corporationTypeName = "supplier";
    } else if (this.isVendorUser) {
      this.corporationTypeName = "vendor";
    }

    this.histories_new = this.histories;
    this.convfieldValue(this.histories_new);
  },
  watch: {
    histories(val) {
      this.histories_new = [...val];
      this.convfieldValue(this.histories_new);
    },
  },
  computed: {
    isField: function () {
      return function (value) {
        return Columns[this.corporationTypeName][this.functionCategory][value];
      };
    },
    convfieldName: function () {
      return function (fieldId) {
        return Columns[this.corporationTypeName][this.functionCategory][fieldId].name;
      };
    },
    convColor: function () {
      return function (corporationType) {
        let ret = null;
        if (corporationType === CorporationTypes.CAFEREO) {
          ret = "orange";
        } else if (corporationType === CorporationTypes.MAKER) {
          ret = "blue";
        } else if (corporationType === CorporationTypes.VENDOR) {
          ret = "pink";
        }
        return ret;
      };
    },
  },
  methods: {
    convfieldValue: async function (histories_new) {
      for (let i = 0; i < histories_new.length; i++) {
        let history = histories_new[i];
        for (let j = 0; j < history.changes.length; j++) {
          let change = history.changes[j];
          let columns = Columns[this.corporationTypeName][this.functionCategory][change.fieldId];
          if (columns?.master != undefined) {
            if (change.oldValue instanceof Array) {
              let concatOldValue = "";
              for (const tmpOldValue of change.oldValue) {
                if (tmpOldValue) {
                  let old_response = await this.$store.dispatch("history/decode", {
                    decodeKey: columns.master,
                    decodeValue: tmpOldValue,
                  });
                  concatOldValue +=
                    concatOldValue != ""
                      ? "、" + old_response.data.contents.decodeName
                      : old_response.data.contents.decodeName;
                }
              }
              change.oldValue = concatOldValue;
            } else {
              if (change.oldValue) {
                let old_response = await this.$store.dispatch("history/decode", {
                  decodeKey: columns.master,
                  decodeValue: change.oldValue,
                });
                change.oldValue = old_response.data.contents.decodeName;
              }
            }
            if (change.newValue instanceof Array) {
              let concatNewValue = "";
              for (const tmpNewValue of change.newValue) {
                if (tmpNewValue) {
                  let new_response = await this.$store.dispatch("history/decode", {
                    decodeKey: columns.master,
                    decodeValue: tmpNewValue,
                  });
                  concatNewValue +=
                    concatNewValue != ""
                      ? "、" + new_response.data.contents.decodeName
                      : new_response.data.contents.decodeName;
                }
              }
              change.newValue = concatNewValue;
            } else {
              if (change.newValue) {
                let new_response = await this.$store.dispatch("history/decode", {
                  decodeKey: columns.master,
                  decodeValue: change.newValue,
                });
                change.newValue = new_response.data.contents.decodeName;
              }
            }
          }
          if (columns?.const != undefined) {
            if (change.oldValue instanceof Array) {
              let concatOldValue = "";
              for (const tmpOldValue of change.oldValue) {
                if (tmpOldValue) {
                  let old_response = this.Consts.constants[columns.const].of(tmpOldValue);
                  concatOldValue += concatOldValue != "" ? "、" + old_response : old_response;
                }
              }
              change.oldValue = concatOldValue;
            } else {
              change.oldValue = this.Consts.constants[columns.const].of(change.oldValue);
            }

            if (change.newValue instanceof Array) {
              let concatNewValue = "";
              for (const tmpNewValue of change.newValue) {
                if (tmpNewValue) {
                  let new_response = this.Consts.constants[columns.const].of(tmpNewValue);
                  concatNewValue += concatNewValue != "" ? "、" + new_response : new_response;
                }
              }
              change.newValue = concatNewValue;
            } else {
              change.newValue = this.Consts.constants[columns.const].of(change.newValue);
            }
          }
          if (columns?.type != undefined) {
            if (change.oldValue instanceof Array) {
              let concatOldValue = "";
              for (const tmpOldValue of change.oldValue) {
                if (tmpOldValue) {
                  let old_response = this.typeConvert(columns.type, tmpOldValue);
                  concatOldValue += concatOldValue != "" ? "、" + old_response : old_response;
                }
              }
              change.oldValue = concatOldValue;
            } else {
              change.oldValue = this.typeConvert(columns.type, change.oldValue);
            }

            if (change.newValue instanceof Array) {
              let concatNewValue = "";
              for (const tmpNewValue of change.newValue) {
                if (tmpNewValue) {
                  let new_response = this.typeConvert(columns.type, tmpNewValue);
                  concatNewValue += concatNewValue != "" ? "、" + new_response : new_response;
                }
              }
              change.newValue = concatNewValue;
            } else {
              change.newValue = this.typeConvert(columns.type, change.newValue);
            }
          }
        }
      }
    },
    typeConvert(type, value) {
      let result = "";
      if (value && type == "Date") {
        result = moment(new Date(value)).format("YYYY/MM/DD");
      }
      if (value && type == "DateTime") {
        result = moment(new Date(value)).format("YYYY/MM/DD HH:mm:ss");
      }
      return result;
    },
  },
  filters: {
    moment: function (date) {
      return moment(new Date(date)).format("YYYY/MM/DD HH:mm:ss");
    },
  },
  mounted: function () {
    if (this.isCafereoUser) {
      this.corporationTypeName = "cafereo";
    } else if (this.isMakerUser) {
      this.corporationTypeName = "supplier";
    } else if (this.isVendorUser) {
      this.corporationTypeName = "vendor";
    }
  },
};
</script>
