import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "新規登録", enum: "NEW" },
  { value: 2, text: "返品却下", enum: "RETURN_REJECTED" },
  { value: 3, text: "承認済み", enum: "APPROVED" },
  { value: 4, text: "納品予約済み", enum: "RESERVED" },
  { value: 5, text: "検品済み", enum: "INSPECTED" },
  { value: 6, text: "キャンセル", enum: "CANCELED" },
]);
