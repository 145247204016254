import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "決済待ち", enum: "PAYMENT_WAITING" },
  { value: 2, text: "決裁却下", enum: "PAYMENT_REJECTION" },
  { value: 3, text: "承認待ち", enum: "APPROVAL_WAITING" },
  { value: 4, text: "却下", enum: "REJECTION" },
  { value: 5, text: "出荷日回答待ち", enum: "SHIP_DATE_WAITING" },
  { value: 6, text: "納品予約待ち", enum: "DELIVERY_RESERVATION_WAITING" },
  { value: 7, text: "入荷待ち", enum: "ARRIVAL_WAITING" },
  { value: 8, text: "入荷済み", enum: "ARRIVAL" },
  { value: 9, text: "キャンセル", enum: "CANCEL" },
]);
