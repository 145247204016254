import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "承認待ち" },
  { value: 2, text: "公開前" },
  { value: 3, text: "受注中" },
  { value: 4, text: "受注締め" },
  { value: 5, text: "発注締め" },
  { value: 6, text: "追加受付中" },
  { value: 7, text: "販売中" },
  { value: 8, text: "在庫のみ" },
  { value: 9, text: "販売終了" },
  { value: 10, text: "取扱不可" },
]);

// export default createDefault([
//   { value: 1, text: "承認待ち" },
//   { value: 2, text: "承認済み" },
//   { value: 3, text: "受注締め" },
//   { value: 4, text: "発注締め" },
//   { value: 5, text: "販売中" },
//   { value: 6, text: "在庫のみ" },
//   { value: 7, text: "販売終了" },
//   { value: 8, text: "取り扱い不可" },
// ]);
